import { cva } from 'class-variance-authority';

export const listBoxTrigger = cva(
  [
    'group',
    'rac-focus:outline-none',
    'rac-disabled:bg-grey-100',
    'rac-disabled:cursor-not-allowed',
    'rac-disabled:text-grey-300',
  ],
  {
    variants: {
      size: {
        md: 'py-2 pl-3 pr-4',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

export const listBoxTriggerIcon = cva(
  'text-grey-900 flex-shrink-0 transition-transform group-data-[expanded=true]:rotate-180',
);

export const listBox = cva([
  'flex min-h-0 flex-col gap-y-px overflow-y-auto p-3 focus:outline-none',
]);

export const listBoxItem = cva([
  'min-w-[144px]',
  'flex',
  'items-center',
  'justify-between',
  'gap-x-3',
  'rounded-lg',
  'px-2',
  'py-3',
  'font-light',
  'text-sm',
  'outline-none',
  'transition-colors',
  'text-grey-900',
  'cursor-pointer',
  'text-grey-900',
  'data-[focused="true"]:bg-stone-100',
  'rac-disabled:bg-grey-100',
  'rac-disabled:text-grey-500',
  'rac-disabled:cursor-not-allowed',
  'group',
]);
